<template>
  <div>
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <c-table
          ref="table"
          title="설비 목록"
          :columns="grid.columns"
          :gridHeight="grid.height"
          :data="grid.data"
          :filtering="false"
          :columnSetting="false"
          :usePaging="false"
          :editable="editable"
          noDataLabel="점검계획할 설비를 추가하세요. 저장할 시 점검결과를 등록 할 수 있습니다."
          selection="multiple"
          cardClass="topcolor-orange"
          @linkClick="linkClick"
          rowKey="minEquipmentCheckId"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline>
              <!-- <c-btn v-if="editable && updateMode" :showLoading="false" label="계획완료" icon="save" color="blue"  @btnClicked="planComplete" /> -->
              <c-btn v-if="editable" :showLoading="false" label="추가" icon="add" @btnClicked="add" />
              <c-btn
                v-if="editable"
                :url="insertUrl"
                :isSubmit="isSave"
                :param="grid.data"
                mappingType="POST"
                label="저장"
                icon="save"
                @beforeAction="saveInspection"
                @btnCallback="saveCallback" />
              <c-btn v-if="editable && grid.data.length > 0" :showLoading="false"  label="삭제" icon="remove" @btnClicked="remove" />
            </q-btn-group>
          </template>
          <template v-slot:customArea="{ props }">
            <template>
               <q-btn
                class="tableinnerBtn tableinnerUserSearch"
                :class="{'tableinnerUserSearch-left': true,  'tableinnerUserSearch-right': true }"
                flat
                color="blue-6"
                :label="props.row.mocNo"
                icon="search"
                @click.stop="linkClick(props.row)" />
            </template>
          </template>
        </c-table>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'equipment-plan',
   components: {
  },
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        title: '',
        equipmentCd: '',
        checkDate: '',
        selectData: [],
      }),
    },
  },
  data() {
    return {
      editable: true,
      grid: {
        columns: [
          // {
          //   name: 'plantNm',
          //   field: 'plantNm',
          //   label: '사업장',
          //   align: 'center',
          //   sortable: true
          // },
          {
            name: 'checkDeptName',
            field: 'checkDeptName',
            label: '점검부서',
            required: true,
            style: 'width:100px',
            type: 'dept',
            align: 'center',
            sortable: false,
            deptCd: 'checkDeptCd',
          },
          {
            name: 'equipmentTypeNm',
            field: 'equipmentTypeNm',
            required: true,
            label: '설비유형',
            style: 'width:100px',
            align: 'center',
            sortable: false
          },
          {
            name: 'equipmentCd',
            field: 'equipmentCd',
            required: true,
            label: '설비코드',
            style: 'width:100px',
            align: 'center',
            sortable: false
          },
          {
            name: 'equipmentName',
            field: 'equipmentName',
            label: '설비명',
            required: true,
            style: 'width:200px',
            align: 'left',
            sortable: false
          },
          {
            name: 'mocNo',
            field: 'mocNo',
            label: 'MOC 번호',
            align: 'center',
            style: 'width:120px',
            sortable: false,
            type: "custom",
          },
          {
            name: 'equipmentCheckKindCd',
            field: 'equipmentCheckKindCd',
            label: '점검유형',
            required: true,
            align: 'center',
            style: 'width:130px',
            type: 'select',
            setHeader: true,
            comboItems: [
              { code: 'MCKC000005', codeName: '자체(일상)' },
              { code: 'MCKC000010', codeName: '법정(정기)' },
            ],
            sortable: false,
          },
          {
            name: 'checkScheduleUserName',
            field: 'checkScheduleUserName',
            label: '점검예정자',
            required: true,
            align: 'center',
            style: 'width:120px',
            setHeader: true,
            type: 'user',
            userId: 'checkScheduleUserId',
            sortable: false,
          },
          {
            name: 'checkScheduleDate',
            field: 'checkScheduleDate',
            label: '점검예정일',
            required: true,
            align: 'center',
            style: 'width:150px',
            type: 'date',
            sortable: false,
          },
          {
            name: 'inspectionCycleName',
            field: 'inspectionCycleName',
            label: '법정주기',
            style: 'width:80px',
            align: 'center',
            sortable: false
          },
          {
            name: 'selfCycleName',
            field: 'selfCycleName',
            label: '자체주기',
            style: 'width:80px',
            align: 'center',
            sortable: false
          },
          {
            name: 'recentInspectionDate',
            field: 'recentInspectionDate',
            label: '이전법정점검일',
            style: 'width:100px',
            align: 'center',
            sortable: false
          },
          {
            name: 'recentSelfDate',
            field: 'recentSelfDate',
            label: '이전자체점검일',
            style: 'width:100px',
            align: 'center',
            sortable: false
          },
          {
            name: 'relatedLawsName',
            field: 'relatedLawsName',
            label: '관련법규',
            align: 'left',
            style: 'width:300px',
            sortable: false
          },
          // {
          //   name: 'remark',
          //   field: 'remark',
          //   label: '비고',
          //   align: 'left',
          //   style: 'width:200px',
          //   type: 'textarea',
          //   sortable: false,
          // },
        ],
        data: [],
        height: '700px'
      },
      title: '',

      getUrl: '',
      insertUrl: '',
      updateUrl: '',
      completeUrl: '',
      deleteUrl: '',

      isSave: false,
      updateMode: false,

      selectDate: '', 
      isOpen: false,
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },

      row: null,
    };
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
    this.init();
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // list setting
      this.editable = this.$route.meta.editable;
      this.insertUrl = transactionConfig.sop.min.equipment.plan.insert.url;
      this.deleteUrl = transactionConfig.sop.min.equipment.plan.delete.url;
      this.completeUrl = transactionConfig.sop.min.equipment.plan.complete.url;
      this.getUrl = selectConfig.sop.min.equipment.annual.get.url;

      if (this.popupParam.selectData.length > 0) {
        this.$_.forEach(this.popupParam.selectData, _item => {
          this.grid.data.splice(0, 0, {
              minEquipmentCheckId: '',
              plantCd: _item.plantCd,
              plantNm: _item.plantNm,
              checkDeptCd: _item.managementDepts,
              checkDeptName: _item.managementDeptName,  
              mocNo: '',
              equipmentCheckKindCd: null,
              checkStatusCd: 'MCSC000005',
              checkScheduleUserName: '', 
              checkScheduleUserId: '',
              checkScheduleDate: '',
              equipmentTypeCd: _item.equipmentTypeCd, 
              equipmentTypeNm: _item.equipmentTypeNm,
              equipmentCd: _item.equipmentCd, 
              equipmentName: _item.equipmentName,
              inspectionCycleName: _item.inspectionCycleName,
              inspectionCycleCd: _item.inspectionCycleCd,
              recentInspectionDate: _item.recentInspectionDate,
              recentSelfDate: _item.recentSelfDate,
              selfCycleName: _item.selfCycleName,
              relatedLawsName: _item.relatedLawsName,
              noPlanResultEnrollFlag: 'N',
              relatedLaws: _item.relatedLaws,
              regUserId: this.$store.getters.user.userId,
              chgUserId: this.$store.getters.user.userId,
              month: 'temp',
              editFlag: 'C',
            })
        })
      }
      this.title = this.popupParam.title;

      this.getList();
    },
    getList() {
      if (this.popupParam.equipmentCd) {
        this.$http.url = this.$format(this.getUrl, this.popupParam.equipmentCd, this.popupParam.checkDate);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.grid.data = _result.data;
          this.updateMode = true;
        },);
      }
    },
    add() {
      this.popupOptions.title = '연간점검계획 대상 설비 선택'; // 연간점검계획 대상 설비 선택
      this.popupOptions.param = {
        type: 'multiple',
        title: '점검'
      };
      this.popupOptions.target = () => import(`${'@/pages/common/equip/equipPop.vue'}`);
      this.popupOptions.width = '50%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeEquipmentPopup;
    },
    closeEquipmentPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
          // let index = this.$_.findIndex(this.grid.data, {
          //   equipmentCd: _item.equipmentCd,
          // });
          // if (index === -1) {
          this.grid.data.splice(0, 0, {
            minEquipmentCheckId: uuidv4(),
            plantCd: _item.plantCd,
            plantNm: _item.plantNm,
            checkDeptCd: _item.managementDepts,
            checkDeptName: _item.managementDeptName,  
            mocNo: '',
            equipmentCheckKindCd: null,
            checkStatusCd: 'MCSC000005',
            checkScheduleUserName: '', 
            checkScheduleUserId: '',
            checkScheduleDate: '',
            equipmentTypeCd: _item.equipmentTypeCd, 
            equipmentTypeNm: _item.equipmentTypeName,
            equipmentCd: _item.equipmentCd, 
            equipmentName: _item.equipmentName,
            inspectionCycleName: _item.inspectionCycleName,
            inspectionCycleCd: _item.inspectionCycleCd,
            selfCycleName: _item.selfCycleName,
            selfCycleCd: _item.selfCycleCd,
            recentInspectionDate: _item.recentInspectionDate,
            recentSelfDate: _item.recentSelfDate,
            relatedLawsName: _item.relatedLawsName,
            relatedLaws: _item.relatedLaws,
            regUserId: this.$store.getters.user.userId,
            chgUserId: this.$store.getters.user.userId,
            noPlanResultEnrollFlag: 'N',
            month: 'temp',
            editFlag: 'C',
          })
          // }
        })
      }
    },
    saveInspection() {
      let isConti = true;
      if (this.grid.data.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '설비를 추가하세요.', // 설비를 추가하세요.
          type: 'warning', // success / info / warning / error
        });
        isConti = false;
        return;
      }
      let checkItem = ['checkDeptName', 'equipmentCheckKindCd', 'checkScheduleUserName', 'checkScheduleDate' ]
      this.$_.forEach(this.grid.data, item => {
        this.$_.forEach(checkItem, check => {
          if (!item[check]) {
            isConti = false;
            return false;
          }
        })
      });
      if (!isConti) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '필수 입력값을 입력해 주세요. [점검부서, 점검유형, 점검예정자, 점검예정일]',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.isSave = !this.isSave;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback() {
      this.emit('SAVE');
    },
    planComplete() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        let isConti = true;
        if (selectData.length === 0) {
          window.getApp.$emit('ALERT', {
            title: '안내', // 안내
            message: '설비를 추가하세요.', // 설비를 추가하세요.
            type: 'warning', // success / info / warning / error
          });
          isConti = false;
          return;
        }
        let checkItem = ['checkDeptName', 'equipmentCheckKindCd', 'checkScheduleUserName', 'checkScheduleDate' ]
        this.$_.forEach(this.grid.data, item => {
          this.$_.forEach(checkItem, check => {
            if (!item[check]) {
              isConti = false;
              return false;
            }
          })
        });
        if (!isConti) {
          window.getApp.$emit('ALERT', {
            title: '안내', // 안내
            message: '필수 입력값을 입력해 주세요. [점검부서,점검유형, 점검예정자, 점검예정일]',
            type: 'warning', // success / info / warning / error
          });
        } else {
          window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장되지 않은 설비점검은 저장과 함께 계획완료 됩니다. 계획완료하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.completeUrl;
            this.$http.type = 'PUT';
            this.$http.param = selectData;
            this.$http.request(() => {
              this.emit('COMPLETE')
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
        }
      }
    },
    remove() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.deleteUrl;
          this.$http.type = 'DELETE';
          this.$http.param = {
            data: Object.values(selectData)
          }
          this.$http.request(() => {
            this.$_.forEach(selectData, item => {
              this.grid.data = this.$_.reject(this.grid.data, { minEquipmentCheckId: item.minEquipmentCheckId })
          })
            window.getApp.$emit('APP_REQUEST_SUCCESS');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
      }
    },
    linkClick(row) {
      this.popupOptions.title = "설비관련 MOC 검색"; // 설비관련 MOC 검색
      this.popupOptions.param = {
        type: 'single'
      };
      this.row = row;
      this.popupOptions.target = () => import(`${'@/pages/sop/moc/mocPop.vue'}`)
      this.popupOptions.visible = true;
      this.popupOptions.width = '60%';
      this.popupOptions.isFull = false;
      this.popupOptions.closeCallback = this.closeMocPopup;
    },
    closeMocPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (!data) return;

      this.row.mocNo = data.chngNum
    },
    closePopup(type) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (type === '계획완료' || type === '저장') {
         window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: type +  ' 하였습니다.', 
          type: 'success', // success / info / warning / error
        });
      }
      this.getList();
    },
    emit(result) {
      if (result === 'COMPLETE') {
        this.$emit('closePopup', result);
      } else if (result === 'SAVE') {
        this.$emit('closePopup', result);
      } 
    },
  }
};
</script>